import React from "react";

import "./DottiScan.scss";
import { Scanner, CustomHeader } from "SharedComponents";
import { ALGORITHMS } from "Constants";
import { PRODUCTS } from "Constants";
import { withRouter } from "react-router";

class DottiScan extends React.Component {
  constructor(props) {
    super(props);
    const algorithmKey = this.props.match.params.algorithm;
    this.state = {
      algorithm: ALGORITHMS.find((algo) => algo.key === algorithmKey)
        .projectName,
    };
  }

  componentDidMount() {
    document.title = "Arylla Scanner";
  }

  render() {
    const { algorithm } = this.state;
    const dottiWalterLogoPath =
      process.env.PUBLIC_URL + "/dotti-walter-logo.png";
    return (
      <div className="embedded-scan-page dotti">
        <CustomHeader logo={dottiWalterLogoPath} />
        <Scanner
          algorithm={algorithm}
          products={PRODUCTS}
          url={this.props.match.url}
          customText={{}}
        />
      </div>
    );
  }
}

export default withRouter(DottiScan);
